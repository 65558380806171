import React from "react";
import classes from "./Gallery.module.css";

import backgroundImage from "../../assets/images/room1.jpg";
import backgroundImage2 from "../../assets/images/pexels-cottonbro-studio-4667030.jpg";

import galleryImage1 from "../../assets/images/photos/Banquettes.jpg";
import galleryImage2 from "../../assets/images/photos/Center Room.jpg";
import galleryImage3 from "../../assets/images/photos/Draft Taps Closeup 2.jpg";
import galleryImage4 from "../../assets/images/photos/Food Spread.jpg";
import galleryImage5 from "../../assets/images/photos/Full Room 2.jpg";
import galleryImage6 from "../../assets/images/photos/People Drinking 1.jpg";
import galleryImage7 from "../../assets/images/photos/Samurai Cat.jpg";
import galleryImage8 from "../../assets/images/photos/Draft Taps Closeup 3.jpg";
import galleryImage9 from "../../assets/images/photos/Room Action 1.jpg";
import galleryImage10 from "../../assets/images/photos/Draft Taps Closeup 4.jpg";

const imageList = [
  galleryImage1,
  galleryImage2,
  galleryImage3,
  galleryImage4,
  galleryImage5,
  galleryImage8,
  galleryImage6,
  galleryImage7,
  galleryImage10,
  galleryImage9,
];

const Gallery = ({ className }) => {
  const classesList = `${classes.main} ${className}`;
  const images = imageList.map((image) => {
    return (
      <div className={classes.left}>
        <img className={classes.background} src={image} alt="background" />
      </div>
    );
  });
  return (
    <div className={classesList}>
      <div className={classes.container}>{images}</div>
    </div>
  );
};

export default Gallery;
