import React from "react";
import classes from "./MenuItem.module.css";

const MenuItem = ({ className, active, onClick, selected, title, ings, price }) => {
  const classesList = `${classes.main} ${className} ${
    !active && classes.inactive
  } ${active && classes["color" + (selected % 6)]}`;
  return (
    <div className={classesList} onMouseEnter={onClick}>
      <h4 className={classes.title}>{title}</h4>
      {/* <p className={classes.subtitle}>Notes:</p> */}
      {/* <p className={classes.text}>Vanilla, Rosemary, Mint</p> */}
      <p className={classes.text}>{ings.join(", ")}</p>
      <div className={classes.price}>${price || 'MP'}<span className={classes.small}></span></div>
    </div>
  );
};

export default MenuItem;
