import React, { useEffect } from "react";
import classes from "./Reservations.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const Reservations = ({ className, widget }) => {
  const classesList = `${classes.main} ${className}`;

  useEffect(() => {
    console.log("runninf effect");

    const script = document.createElement("script");

    script.src =
      "//www.opentable.ca/widget/reservation/loader?rid=1316143&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=ca&lang=en-CA&newtab=false&ot_source=Restaurant%20website&cfe=true";
    script.async = true;

    // document.querySelector("section").innerHTML = <></>;
    document.querySelector("section").appendChild(script);

    return () => {
      //   console.log("rmving chjild");
      //   document.querySelector("section").innerText = "";
    };
  }, []);
  return (
    <div className={classesList}>
      <section className={classes.box}>
{/*      
        {widget}
        <script
          type="text/javascript"
          src="//www.opentable.ca/widget/reservation/loader?rid=1316143&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=ca&lang=en-CA&newtab=false&ot_source=Restaurant%20website&cfe=true"
        ></script> */}
        {/* <div className={classes.email}>
        <FontAwesomeIcon icon={faCalendar} className={classes.icon}  style={{'color':'white'}}/>
        <a href="mailto:reservations@overpressureclub.com">
          <h4 className={classes.email}  style={{'color':'white'}}>reservations@overpressureclub.com</h4>
        </a>
        
        </div> */}


        
        
       
      </section>
    </div>
  );
};

export default Reservations;
