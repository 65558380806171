import React from "react";
import classes from "./Brunch.module.css";
import MenuItem from "../menu/MenuItem";
import DUMMY_COCKTAILS from "../../devData/dummyCocktails.json";
import backgroundImage2 from "../../assets/images/pexels-cottonbro-studio-5921123 copy.jpg";
import backgroundImage1 from "../../assets/images/pexels-cottonbro-studio-3338537 copy.jpg";
import backgroundImage3 from "../../assets/images/pexels-karolina-grabowska-4021983 copy.jpg";
import backgroundImage4 from "../../assets/images/cocktailimage.jpg";
import backgroundImage5 from "../../assets/images/pexels-cottonbro-studio-3297882 copy.jpg";
import backgroundImage6 from "../../assets/images/pexels-karolina-grabowska-4021983 copy.jpg";
import { useState } from "react";

import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
import oysterMush from "../../assets/images/photos/Oyster Mushroom.jpg";
import tofu from "../../assets/images/photos/Tofupokki.jpg";
import loadedFries from "../../assets/images/photos/Loaded Fries.jpg";
import karaage from "../../assets/images/photos/Overpressure Karaage.jpg";
import porkJowl from "../../assets/images/photos/Pork Jowl.jpg";
import shortRib from "../../assets/images/photos/Shortrib.jpg";
import eggplant from "../../assets/images/photos/Miso Eggplant.jpg";

import berry from "../../assets/images/photos/Berry Matcha Toast.jpg";


import oysters from "../../assets/images/photos/Oysters.jpg";
import shrimp from "../../assets/images/photos/Shrimp Cocktail.jpg";
import pickles from "../../assets/images/photos/House Pickles.jpg";
import cucumber from "../../assets/images/photos/Cucumber Salad.jpg";

import pearl from "../../assets/images/photos/Harajuku Pearl.jpg";
import mochi from "../../assets/images/photos/Harajuku Pearl.jpg";
import cotton from "../../assets/images/photos/Kurogama Cotton.jpg";
import toast from "../../assets/images/photos/Nori Honey Toast.jpg";

import cosmo from "../../assets/images/photos/Cosmopop.jpg";
import powerpuff from "../../assets/images/photos/Powerpuff Pisco.jpg";
import peach from "../../assets/images/photos/Peach Punks.jpg";
import branca from "../../assets/images/photos/Branca Bouquet.jpg";
import ricey from "../../assets/images/photos/Ricey Dicey.jpg";
import amari from "../../assets/images/photos/Amari Kano.jpg";
import scarecrow from "../../assets/images/photos/Scarecrow_s Harvest.jpg";
import tea from "../../assets/images/photos/Tea for One.jpg";
import queen from "../../assets/images/photos/Queen of Hearts.jpg";
import pandan from "../../assets/images/photos/Pandan o Rama.jpg";
import tobermory from "../../assets/images/photos/Tobermory Trail.jpg";
import shishito from "../../assets/images/photos/Holy Shishito.jpg";
import tropic from "../../assets/images/photos/Tropic Pursuit.jpg";
import verde from "../../assets/images/photos/Double Verde Colada.jpg";
import pear from "../../assets/images/photos/Shiso Pear Martini.jpg";
import ground from "../../assets/images/photos/From The Ground Up.jpg";
import raised from "../../assets/images/photos/Raised By Wolves.jpg";
import yoichi from "../../assets/images/photos/Yoichi The Killer.jpg";
import standing from "../../assets/images/photos/Standing Tall.jpg";
import parrallel from "../../assets/images/photos/Parallel Universe 1.jpg";

import cafe1 from "../../assets/images/photos/Cafe Highball 1.jpg";
import cafe2 from "../../assets/images/photos/Cafe Highball 2.jpg";
import corn from "../../assets/images/photos/Corn Croquettes.jpg";

import fluff from "../../assets/images/photos/Fluff N Stuff.jpg";
import gran from "../../assets/images/photos/Granola Bowl.jpg";

import limon from "../../assets/images/photos/Limonata 1.jpg";
import limon2 from "../../assets/images/photos/Limonata 2.jpg";
import melon from "../../assets/images/photos/Melon Spritz 1.jpg";
import melon2 from "../../assets/images/photos/Melon Spritz 2.jpg";
import mount from "../../assets/images/photos/Mountain Harvest Salad.jpg";
import sam from "../../assets/images/photos/Samurai Sandwich.jpg";
import shog from "../../assets/images/photos/Shogun Breakfast.jpg";

import sunr from "../../assets/images/photos/Sunrise on Bloor 1.jpg";
import sunr2 from "../../assets/images/photos/Sunrise on Bloor 2.jpg";
import swee from "../../assets/images/photos/Sweet Pea Martini 1.jpg";
import swee2 from "../../assets/images/photos/Sweet Pea Martini 2.jpg";
import tama from "../../assets/images/photos/Tamago Benedict.jpg";
import tok from "../../assets/images/photos/Tokyo Caesar 1.jpg";
import tok2 from "../../assets/images/photos/Tokyo Caesar 2.jpg";


// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
// import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";

import { addCocktailVariants } from "../../config/config";
import { motion } from "framer-motion";

const images = [
  backgroundImage1,
  backgroundImage2,
  backgroundImage3,
  backgroundImage4,
  backgroundImage5,
  backgroundImage6,
];

const titles = [
  "cocktails on tap",
  "izakaya food",
  "stirred cocktails",
  "shaken cocktails",
];

const cocktails = [
  // {
  //   title: "Fluff ‘N’ Stuff",
  //   ings: "FLUFFY GRAPEFRUIT JUICE, GRAPEFRUIT ORANGE APERITIVO, HERBAL YELLOW LIQUEUR, AMARO",
  //   image: fluff,
  //   type: "Signature Cocktails",
  //   price: 18
  // },
  {
    title: "Sunrise on Bloor",
    ings: "AGED JAMAICAN RUM, BOURBON, CHAI, SHOKUPAN, MAPLE, CORN MILK, ORANGE, LEMON, CHOCOLATE BITTERS",
    image: sunr,
    type: "Signature Cocktails",
    price: 15
  }, 

  {
    title: "Mikan Mimosa",
    ings: "BLANCO TEQUILA, SPANISH LIQUEUR, MARASCHINO, CONDENSED MILK, OLEO, LEMON, MINT, SODA",
    image: null,
    type: "Signature Cocktails",
    price: 14
  },
  {
    title: "Melon Spritz",
    ings: "MELON LIQUEUR, AROMATIZED WINE, HONEYDEW, HERBAL YELLOW LIQUEUR, LIME, BUBBLES",
    image: melon,
    type: "Signature Cocktails",
    price: 15
  },
  {
    title: "Tokyo Caesar",
    ings: "SESAME & CHILI VODKA, FERMENTED CELERY, SEASONING MIX, CLAMATO, TOGARASHI",
    image: tok,
    type: "Signature Cocktails",
    price: 14
  },
  {
    title: "Breakfast Limonata",
    ings: "BLANCO TEQUILA, SPANISH LIQUEUR, MARASCHINO, CONDENSED MILK, OLEO, LEMON, MINT, SODA",
    image: limon,
    type: "Signature Cocktails",
    price: 15
  },
  {
    title: "Café Highball",
    ings: "JAPANESE WHISKY, AMARO, COLD BREW, ORANGE LIQUEUR, LEMON, TONIC",
    image: cafe1,
    type: "Signature Cocktails",
    price: 15
  },
  // {
  //   title: "Sweet Pea Martini",
  //   ings: "RICE SHOCHU, SAKE, SPANISH BLANCO TEQUILA, SNAP PEA CORDIAL, BAIJU",
  //   image: swee,
  //   type: "Signature Cocktails",
  //   price: 18
  // },





  // {
  //   title: "Double verde colada",
  //   ings: "white rum, green chatreuse, calpico, toasted pumpkin seed, 3 X coconut, fermented and fresh pineapple, matcha",
  //   image: verde,
  //   type: "Signature Cocktails",
  // },

  // {
  //   title: "from the ground up",
  //   ings: "rye, akvavit, red bitter blend, beetroot vermouth, maraschino, celery bitters, peychauds",
  //   image: ground,
  //   type: "Signature Cocktails",
  // },



];

const hotFood = [
  {
    title: "Karaage & Waffles",
    ings: "Waffles, fried chicken, nori butter, spicy maple honey",
    image: null,
    type: "Mains",
    price: 19
  },
  {
    title: "Shogun Breakfast",
    ings: "Classic breakfast; shokupan toast, two eggs, breakfast potatoes, side salad. Choice of thick cut bacon OR curry chicken sausage OR curry marinated tempeh",
    image: shog,
    type: "Mains",
    price: 19
  },
  {
    title: "Tamago Benedict",
    ings: "Shokupan bun, two poached eggs, miso/citrus hollandaise, breakfast potatoes, side salad. Choice of curry chicken sausage OR thick cut bacon OR black sesame spinach",
    image: tama,
    type: "Mains",
    price: 21
  },
  {
    title: "Samurai Sandwich",
    ings: "Shokupan bun, togarashi fried egg, curry chicken sausage, chili mayo, broccolini. Side of breakfast potato and side salad",
    image: sam,
    type: "Mains",
    price: 18
  },
  {
    title: "Mountain Harvest Salad",
    ings: "Roasted white yam, kale, lettuce mix, red cabbage, sweet pickled onion, pickled raisins, pumpkin seed sesame crumble, carrot ginger dressing. Add tempeh +$6",
    image: mount,
    type: "Mains",
    price: 16
  },

  {
    title: "Berries Matcha French Toast",
    ings: "Two pieces cornflake crusted french toast, field berry preserves, matcha soft creme, white chocolate anglaise",
    image: berry,
    type: "Mains",
    price: 19
  },
  // {
  //   title: "Strawberry Matcha French Toast",
  //   ings: "Two pieces cornflake crusted french toast, strawberry preserves, matcha soft creme, white chocolate anglaise",
  //   image: berry,
  //   type: "Mains",
  //   price: 21
  // },

];

const coldFood = [
  {
    title: "Breakfast Potatoes",
    ings: "Crushed potatoes, herb oil, togarashi",
    image: null,
    type: "Sides",
    price: 9
  },
  {
    title: "Bacon/ Sausage/ Tempeh • 2/2/3 pcs",
    ings: "Thick cut bacon OR curry chicken sausage OR curry marinated tempeh",
    image: null,
    type: "Sides",
    price: 7
  },
  {
    title: "Side Salad",
    ings: "Lettuce mix, carrot ginger dressing",
    image: null,
    type: "Sides",
    price: 6
  },
  // {
  //   title: "Fruit, Yogurt and Granola Bowl",
  //   ings: "Yuzu yogurt, house made granola, strawberry preserves",
  //   image: gran,
  //   type: "Sides",
  //   price: 12
  // },
  // {
  //   title: "Oysters (Half Dozen / Dozen)",
  //   ings: "Leek mignonette, pink peppercorn, lacto chili sauce, wasabi, lemon",
  //   image: oysters,
  //   type: "Sides",
  //   price: 'MP'
  // },
];

const coffee = [
  {
    title: "Brewed Coffee (Bottomless)",
    ings: "Carbonic Coffee • Colombian",
    image: null,
    type: "Coffee",
    price: 5
  },
  // {
  //   title: "Mini Miso Mochi",
  //   ings: "Mochi, miso caramel whip, banana, kinako powder",
  //   image: null,
  //   type: "Coffee",
  // },
  // {
  //   title: "Kurogoma Cotton",
  //   ings: "Japanese cheesecake, sesame brittle, yuzu gel",
  //   image: cotton,
  //   type: "Coffee",
  // },
  {
    title: "Cold Brew Coffee",
    ings: "Backroad Coffee Roasters • Cold brew, milk, maple syrup",
    image: null,
    type: "Coffee",   
    price: 7
  },
];

const teas = [
  {
    title: "Tea",
    ings: "Genuine Tea • Hojicha or Sencha Kyoto",
    image: null,
    type: "Tea",
    price: 5
  },
  {
    title: "Iced Sakura Rose Tea",
    ings: "Genuine Tea • Organic Sakura rose tea, matcha, soy milk",
    image: null,
    type: "Tea",  
    price: 7
  },
];

const juice = [
  {
    title: "Super Freshest Juice",
    ings: "Orange or Grapefruit • Squeezed or fluffy, Pineapple • Fluffy, Fluffed to order and squeezed daily",
    image: null,
    type: "Juice",
    price: 6
  },
];

const beer = [
  
  {
    title: "Rorschach Japanese Rice Lager",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 8
  },
  {
    title: "Godspeed Yuzu Citrus Saison",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 9
  },
  {
    title: "Godspeed Ochame Green Tea IPA",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 9
  },
  {
    title: "Harmon's Non-Alcoholic Pale Ale",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 8
  },
  {
    title: "Hipster Beer",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 6
  },
];

const wine = [
  
  {
    title: "Speri Valpolicella Classico (IT)",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 15
  },
  {
    title: "Map Maker Sauvignon Blanc (NZ)",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 15
  },
  {
    title: "Trevisana Prosecco Millesimato Extra Dry N.V.",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 14
  },
];

const Menu = ({ className }) => {
  const classesList = `${classes.main} ${className}`;
  const [selected, setSelected] = useState(1);
  const [subtitle, setSubtitle] = useState("Mains");
  const [cocktailImage, setCocktailImage] = useState(null);
  const selectCocktailHandler = (cocktail, image) => {
    setSelected(cocktail);
    // setSubtitle(cocktail.type);
    setCocktailImage(image);
  };
  return (
    <motion.div
      className={classesList}
      // variants={addCocktailVariants}
      initial={{
        x: "100%",
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
        // transition: {
        //   duration: 0.5,
        // },
      }}
      exit={{
        // x: "-100%",
        opacity: 1,

        // transition: {
        //   //   type: "spring",

        //   duration: 0.5,
        // },
      }}
    >
      <div className={classes.left}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>{subtitle}</h1>
        </div>
        {cocktailImage && (
          <img
            className={classes.background}
            src={cocktailImage}
            alt="background"
          />
        )}
      </div>
      <div className={classes.right}>
        <div className={classes.menuContainer}>
        {/* <h3>Brunch</h3> */}
          <h4 className={classes.subtitle}>Mains</h4>
          <div className={classes.menuBox}>
            {hotFood.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  active={selected === i + 1}
                  price={cocktail.price}
                  onClick={() => {
                    selectCocktailHandler(i + 1, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Sides</h4>
          <div className={classes.menuBox}>
            {coldFood.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  active={selected === i + 1 + 80}
                  price={cocktail.price}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 80, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Signature Cocktails</h4>
          <div className={classes.menuBox}>
            {cocktails.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  active={selected === i + 1 + 40}
                  price={cocktail.price}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 40, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Coffee</h4>
          
          <div className={classes.menuBox}>
            {coffee.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 120}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 120, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          
          <h4 className={classes.subtitle}>Tea</h4>
          <div className={classes.menuBox}>
            {teas.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 160}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 160, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Juices</h4>
          <div className={classes.menuBox}>
            {juice.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 200}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 200, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Wine</h4>
          <div className={classes.menuBox}>
            {wine.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 220}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 220, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Beer/Cider</h4>
          <div className={classes.menuBox}>
            {beer.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 240}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 240, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Menu;
