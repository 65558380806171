import React from "react";
import classes from "./LandingVideo.module.css";

import gif from "../../assets/video/OPC_Desktop_animation_2.gif";

const LandingVideo = ({ className, show }) => {
  const classesList = `${classes.main} ${className}`;
  return (
    show && (
      <div className={classesList}>
        <img src={gif} alt="" className={classes.img} />
      </div>
    )
  );
};

export default LandingVideo;
