// Main imports
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { useEffect, useState } from "react";

import Navigation from "./UI/Navigation/Navigation";
import About from "./pages/about/About";
import Menu from "./pages/menu/Menu";
import Brunch from "./pages/brunch/Brunch";
import Specials from "./pages/specials/Specials";
import Video from "./pages/video/Video";

import "./App.css";
import Footer from "./UI/Footer/Footer";
import Gallery from "./pages/gallery/Gallery";
import MobileMenu from "./UI/Navigation/MobileMenu";
import LandingVideo from "./UI/LandingVideo/LandingVideo";

import { AnimatePresence } from "framer-motion";
import Reservations from "./pages/reservations/Reservations";

function App() {
  const location = useLocation();
  const [menu, setMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showLanding, setShowLanding] = useState(true);
  const page = useLocation().pathname;
  const [element, setElement] = useState("");

  useEffect(() => {
    // const script = document.createElement("script");
    // script.src =
    //   "//www.opentable.ca/widget/reservation/loader?rid=1316143&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=ca&lang=en-CA&newtab=false&ot_source=Restaurant%20website&cfe=true";
    // script.async = true;
    // setElement(script);
  }, []);

  useEffect(() => {
    // execute on location change
    const url = window.location.href;
    console.log(url.split("/")[3]);
    const menu = url.split("/")[3] !== "";
    console.log(menu);
    setMenu(menu);
    console.log("Location changed!", location.pathname);
  }, [location]);
  useEffect(() => {
    setTimeout(() => {
      setShowLanding(false);
    }, 3000);
  }, []);
  return (
    <div className="App">
      <LandingVideo show={showLanding} />
      <Navigation
        open={() => {
          setMenuOpen(true);
        }}
        isOpen={menuOpen}
      />
      <AnimatePresence exitBeforeEnter>
        <MobileMenu show={menuOpen} close={() => setMenuOpen(false)} />
      </AnimatePresence>
      {/* <AnimatePresence exitBeforeEnter> */}
      <Routes location={location} key={location.key}>
        <Route path="/" element={<About />} />
        <Route path="/about" element={<About />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/brunch" element={<Brunch />} />
        <Route path="/specials" element={<Specials />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route
          path="/reservations"
          element={<Reservations widget={element} />}
        />
      </Routes>
      {/* </AnimatePresence> */}
      <Footer menu={menu} />
    </div>
  );
}

export default App;
