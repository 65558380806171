import React from "react";
import classes from "./Navigation.module.css";
import logo from "../../assets/logos/OPC_Wordmark.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareTwitter } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navigation = ({ className, open, isOpen }) => {
  const classesList = `${classes.main} ${className}`;
  return (
    <div className={classesList}>
      {!isOpen && (
        <div className={classes.burger} onClick={open} isOpen={isOpen}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      )}
      <div className={classes.logo}>
        <Link className={classes.menuItem} to="/">
          <img src={logo} alt="OPC Logo" className={classes.svg} />
        </Link>
      </div>
      <div className={classes.menu}>
        <ul className={classes.menuItems}>
          <li className={classes.menuItem}>
            <Link className={classes.menuItem} to="/menu">
              Menu
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link className={classes.menuItem} to="/brunch">
              Brunch
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link className={classes.menuItem} to="/specials">
              Specials
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link className={classes.menuItem} to="/gallery">
              Gallery
            </Link>
          </li>
          
          <li className={classes.menuItem}>
            <Link className={classes.menuItem} to="/reservations">
              Reservations
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link className={classes.menuItem} to="/about">
              About
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
