import React from "react";
import classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import {
  faSquareTwitter,
  faInstagram,
  faInstagramSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faCalendar } from "@fortawesome/free-regular-svg-icons";

const Footer = ({ className, menu }) => {
  const classesList = `${classes.main} ${className}`;

  return (
    <div className={classesList}>
      <div className={classes.socials + " " + (true && classes.white)}>
        {/* <FontAwesomeIcon icon={faSquareTwitter} />
        <FontAwesomeIcon icon={faInstagramSquare} /> */}
        <div className={classes.emails}>
          <div className={classes.emailCont}>

          <FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
          <a href="mailto:info@overpressureclub.com">
            <h4 className={classes.email}>info@overpressureclub.com</h4>
          </a>
          
          </div>
          <div className={classes.emailCont}>

          <FontAwesomeIcon icon={faCalendar} className={classes.icon} />
          <a href="mailto:reservations@overpressureclub.com">
            <h4 className={classes.email}>reservations@overpressureclub.com</h4>
          </a>
          </div>
        </div>
        {/* <h6 className={classes.newAdd2}>
        Sun-Weds 5PM-1AM || Thurs-Sat 5PM-2AM
        </h6> */}
        <h6 className={classes.newAdd2}>
        Mon CLOSED || Tues-Weds 5:30PM-Midnight || Thurs-Fri 5:30PM-1AM || Sat 11AM-1AM || Sun 11AM-Midnight
        </h6>
        <h6 className={classes.newAdd}>
          815 Bloor Street West, TORONTO ON
        </h6>
      </div>

      <div className={classes.address}>
        {/* <h6>Sun-Weds 5PM-1AM || Thurs-Sat 5PM-2AM</h6> */}
        <h6>Mon CLOSED || Tues-Weds 5:30PM-Midnight || Thurs-Fri 5:30PM-1AM || Sat 11AM-1AM || Sun 11AM-Midnight</h6>
        <h6>815 Bloor Street West, TORONTO ON</h6>
      </div>
    </div>
  );
};

export default Footer;
