import React from "react";
import classes from "./MobileMenu.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { addCocktailVariants } from "../../config/config";

const MobileMenu = ({ className, show, close }) => {
  const classesList = `${classes.main} ${className} ${show && classes.show}`;
  return (
    <motion.div
      className={classesList}
      variants={addCocktailVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className={classes.close} onClick={close}>
        <FontAwesomeIcon icon={faXmark} />
      </div>
      <div className={classes.menu}>
        <Link to="/menu" onClick={close}>
          <h2>Menu</h2>
        </Link>
        <Link to="/brunch" onClick={close}>
          <h2>Brunch</h2>
        </Link>
        <Link to="/specials" onClick={close}>
          <h2>Specials</h2>
        </Link>
        <Link to="/gallery" onClick={close}>
          <h2>Gallery</h2>
        </Link>
        <Link to="/reservations" onClick={close}>
          <h2>Reservations</h2>
        </Link>
        <Link to="/" onClick={close}>
          <h2>About</h2>
        </Link>
      </div>
    </motion.div>
  );
};

export default MobileMenu;
