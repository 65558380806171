export const addCocktailVariants = {
  hidden: {
    x: "100%",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,

    transition: {
      //   type: "spring",

      duration: 0.5,
    },
  },
  exit: {
    // x: "-100%",
    opacity: 1,

    transition: {
      //   type: "spring",

      duration: 0.5,
    },
  },
};
