import React, { useEffect } from "react";
import classes from "./Specials.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const Specials = ({ className, widget }) => {
  const classesList = `${classes.main} ${className}`;

 
  return (
    <div className={classesList}>
      <section className={classes.box}>
     
        {/* {widget} */}
        {/* <script
          type="text/javascript"
          src="//www.opentable.ca/widget/reservation/loader?rid=1316143&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=ca&lang=en-CA&newtab=false&ot_source=Restaurant%20website&cfe=true"
        ></script> */}
        <div className={classes.email}>
        <h2>Sunday</h2>
        <h4>
            50% Off Sake
        </h4>
        
        
        </div>
        <div className={classes.email}>
        <h2>Tuesday</h2>
        <h4>
        $7 PigMac
            
        </h4>
        
        
        
        </div>
        <div className={classes.email}>
        <h2>Wednesday</h2>
        <h4>
        50% Japanese Whisky
            
        </h4>
        
        
        
        </div>
        <div className={classes.email}>
        <h2>Thursday</h2>
        <h4>
        $10 Featured Cocktail High Balls
        </h4>
        
        
        
        </div>
        <div className={classes.email}>
        <h2>Friday</h2>
        
        <h4>
           Happy Hour Specials 5:30pm - 6:30pm
        </h4>
        
        
        </div>
        <div className={classes.email}>
        <h2>Saturday</h2>
        
        <h4>
           Happy Hour Specials 5:30pm - 6:30pm
        </h4>
        
        
        </div>


        
       
      </section>
    </div>
  );
};

export default Specials;